import helpers from '~/helpers/globalsHelpers';
import { useVehiculosVoStore } from '~/store/vehiculosvo';
import { useVehiculosVnStore } from '~/store/vehiculosvn';
import { useVehiculosElStore } from '~/store/vehiculosel';
import { useVehiculosKm0Store } from '~/store/vehiculoskm0';
import { useMotosVnStore } from '~/store/motosvn';
import { useMotosVoStore } from '~/store/motosvo';


export default function useFiltros(model:String){




    let vehiculoStore;


    switch(model){
        case 'vo' : vehiculoStore = useVehiculosVoStore(); break;
        case 'vn' : vehiculoStore = useVehiculosVnStore(); break;
        case 'el' : vehiculoStore = useVehiculosElStore(); break;
        case 'km0' : vehiculoStore = useVehiculosKm0Store(); break;
        case 'moto-vn' : vehiculoStore = useMotosVnStore(); break;
        case 'moto-vo' : vehiculoStore = useMotosVoStore(); break;
        default: vehiculoStore = useVehiculosVoStore(); break;
    }

   

    /**
     * Creación de los modelos y conexión a través computada con la store
     */
    const filtersNames = ['marcas','modelos','precio_max','provincias','orden','anio_min','anio_max','combustibles','distintivos','cilindrada_min','cilindrada_max','transmisiones','potencia_min','potencia_max'];
    const models = ref({
        marcas:undefined,
        modelos:undefined,
        precio_max:undefined,
        provincias:undefined,
        orden:undefined,
        anio_min:undefined,
        anio_max:undefined,
        combustibles:undefined,
        distintivos:undefined,
        cilindrada_min:undefined,
        cilindrada_max:undefined,
        transmisiones:undefined,

    });

    filtersNames.forEach(filterName => {
        models[filterName] = computed({
            get() {
                return vehiculoStore.filters[filterName];
            },
            set(newValue) {
                vehiculoStore.filters[filterName] = newValue;
            }
        });
    });

    /**
     * Retorna todos los modelos si no hay marca o filtrados si existen marcas
     */

    const filteredModels = computed(() => {

        const brands = vehiculoStore.filters.marcas;
        return brands?.length && brands.length > 0 ? vehiculoStore.initFilters.modelos.filter(item => brands.includes(item.marca)) : vehiculoStore.initFilters.modelos ;
    });

    /**
     * 
     * Retorna los modelos filtrados agrupados por marca
     * 
     */

    const normalizedModels = computed( () => {
       

        const grouped = {};
        if(filteredModels.value?.length){
            filteredModels.value.forEach(function (item) {
                grouped[item.marca] = grouped[item.marca] || [];
                grouped[item.marca].push({ 
                    id: item.id,
                    marca: item.marca,
                    nombre: item.nombre
                });
            });
        }
      
        return grouped;
    });


    /**
     * Determina que label mostrar en el botón de filtro
     * @param {Number} index índice del item
     * @return {String}  valores: por defecto|filtros seleccionados
     */
    const setFilterLabel = (action: String, label: String) => {
        
        let values:String;


        if(action == 'precio_max'){
            

            if(vehiculoStore.filters[action] != ''){
                return 'Hasta '+helpers.formatCurrency(Number(vehiculoStore.filters[action].toString()),0);
            }
            else{
                return label;
            }
        } 
        else{
             values = vehiculoStore.filters[action].toString();
        }
        values = values.replace(',',', ');

        return vehiculoStore.filters[action]?.length > 0 ? values : label ;
    };


    /**
     * Muestra|oculta el contenido de los filtros
     * @param {String} action Acción seteada en el filtro
     */
    const toggleFilter = (action: string) => {
        filters.value.map(item => item.action === action ? item.selected = !item.selected : item.selected = false);
    };

    /**
     * Vacía los filtros
     */

    const cleanFilters = () => {
        vehiculoStore.cleanFilters();
    };

    /**
     * Establece los filtros según el parametro
     */

    const setFilters = (filters: Object) => {
        vehiculoStore.setFilters(filters);
    };
    /**
     * Objeto con la información para construir los filtros
     */
    const filters = ref([
        {
          label: 'Marcas',
          action: 'marcas',
          type: 'multi',
          selected: false,
          model:models.marcas,
          items: vehiculoStore?.initFilters.marcas
        },
        {
          label: 'Modelos',
          action: 'modelos',
          type: 'multi',
          selected: false,
          model:models.modelos,
          items: normalizedModels
        },
        {
          label: 'Precios',
          action: 'precio_max',
          type: 'multi',
          selected: false,
          model:models.precio_max,
          items: vehiculoStore?.initFilters.precio_max
        },
        {
          label: 'Ubicación',
          action: 'provincias',
          type: 'multi',
          selected: false,
          model:models.provincias,
          items: vehiculoStore?.initFilters.provincias
        },
        {
          label: 'Orden',
          action: 'orden',
          type: 'single',
          selected: false,
          model: models.orden,
          items:[
            {value:'ASC', label:'Precio de menor a mayor'},
            {value: 'DESC', label: 'Precio de mayor a menor' },
            {value: 'LAST', label: 'Ultima incorporación' },
            {value: 'DEST', label: 'Relevancia' },
            {value:'ALEA', label:'Aleatorio'}
          ]
        },
        {
            label: 'Desde año',
            action: 'anio_min',
            type: 'single',
            selected: false,
            model: models.anio_min,
            items:vehiculoStore?.initFilters.anios
        },
        {
            label: 'Hasta año',
            action: 'anio_max',
            type: 'single',
            selected: false,
            model: models.anio_max,
            items:vehiculoStore?.initFilters.anios
        },
        {
            label: 'Combustible',
            action: 'combustibles',
            type: 'single',
            selected: false,
            model: models.combustibles,
            items:vehiculoStore?.initFilters.combustibles
        },
        {
            label: 'Distintivos',
            action: 'distintivos',
            type: 'single',
            selected: false,
            model: models.distintivos,
            items:vehiculoStore?.initFilters.distintivos_ambientales
        },
        {
            label: 'Desde',
            action: 'cilindrada_min',
            type: 'single',
            selected: false,
            model: models.cilindrada_min,
            items:vehiculoStore?.initFilters.cilindrada_min
        },
        {
            label: 'Hasta',
            action: 'cilindrada_max',
            type: 'single',
            selected: false,
            model: models.cilindrada_max,
            items:vehiculoStore?.initFilters.cilindrada_max
        },
        {
            label: 'Desde',
            action: 'potencia_min',
            type: 'single',
            selected: false,
            model: models.potencia_min,
            items:vehiculoStore?.initFilters.potencia_min
        },
        {
            label: 'Hasta',
            action: 'potencia_max',
            type: 'single',
            selected: false,
            model: models.potencia_max,
            items:vehiculoStore?.initFilters.potencia_max
        },
        {
            label: 'Transmisión',
            action: 'transmisiones',
            type: 'single',
            selected: false,
            model:models.transmisiones,
            items: vehiculoStore?.initFilters.transmisiones
          },  
          
      ]);
      





  

return { vehiculoStore, setFilterLabel, toggleFilter, filteredModels, normalizedModels, filters, cleanFilters, setFilters};


}